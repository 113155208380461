<template>
  <div>
  </div>
</template>

<script>
  import moment from 'moment';
  import sha256 from 'js-sha256';
  export default {
    name: "old-billing",
    created() {
      let pg = 'https://master.launchpack.co.kr:8081';
      //let pg = 'http://13.209.97.215:8081';
      //let pg = 'http://localhost:8081';

      /*let basket = this.$store.getters.basket;
      let user = this.$store.getters.user;

      this.requests.buyername = user.name;
      this.requests.buyertel = user.phone;
      this.requests.buyeremail = user.email;
      this.requests.goodname = basket.title;
      this.requests.price = basket.price[basket.priceType].billing;*/
      let query = this.$route.query;
      let orderid = `launchpack_${ moment().format('YYYYMMDDHHmmss') + moment().milliseconds() }`;

      this.$axios.post('public/launchpack/service/orderid', {
        email: query.email,
        orderid: orderid,
        price: query.price
      }).then(() => {

        this.requests.buyername = query.name;
        this.requests.buyertel = query.phone;
        this.requests.buyeremail = query.email;
        this.requests.goodname = query.goodname;
        this.requests.price = query.price;


        this.requests.returnurl = pg+'/inicis/pay/INIMobilePayReturn.php';



        let form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', 'https://inilite.inicis.com/inibill/inibill_card.jsp');

        let timestamp = moment().format('YYYYMMDDHHmmss');

        let keys = Object.keys(this.requests);
        for(let i=0; i<keys.length; i++) {
          let key = keys[i];
          if(key === 'orderid') {
            this.input(form, key, orderid);
          }
          else if(key === 'timestamp') {
            this.input(form, key, timestamp);
          }
          else if(key === 'hashdata') {
            let hashdata = sha256.sha256(this.requests.mid + orderid + timestamp + this.requests.merchantkey);
            this.input(form, key, hashdata);
          }
          else
            this.input(form, key, this.requests[key]);
        }


        document.body.appendChild(form);
        form.submit();
      });
    },
    data() {
      return {
        requests: {
          merchantkey: 'UTVHRTRPaEV4YUFYNTBrWGFDZW9hdz09',
          mid: 'launchpack',
          //merchantkey: 'b09LVzhuTGZVaEY1WmJoQnZzdXpRdz09',
          //mid: 'INIBillTst',
          buyername: '이남호',
          goodname: '런치팩 솔루션 월 요금제',
          price: '580000',
          orderid: '',
          returnurl: '',
          merchantreserved: '',
          timestamp: '',
          period: 'M2',
          period_custom: '',
          hashdata: ''
        }
      }
    },
    methods: {
      input(form, name, value) {
        let input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', name);
        input.setAttribute('value', value);
        form.appendChild(input);
      }
    }
  }
</script>

<style scoped>

</style>
